import React, { useState } from 'react';
import './ApiKeyPopup.css';

interface ApiKeyPopupProps {
  apiKey: string;
  onClose: () => void;
}

const ApiKeyPopup: React.FC<ApiKeyPopupProps> = ({ apiKey, onClose }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(apiKey);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000); 
  };

  return (
    <div className="api-key-popup">
      <h3>New API Key Generated</h3>
      <p>Please copy and store this key securely. You won't be able to see it again!</p>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}> {/* Keep this inline style for flex layout */}
        <input type="text" value={apiKey} readOnly />
        <button onClick={handleCopy}>
          {copied ? 'Copied!' : 'Copy'} 
        </button>
      </div>
      <button onClick={onClose}>
        Close
      </button>
    </div>
  );
};

export default ApiKeyPopup;