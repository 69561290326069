import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import './DashboardLayout.css';

const DashboardLayout: React.FC = () => {
    return (
        <div className="dashboard-container">
            <div className="sidebar">
                <ul>
                    <li><Link to="/dashboard">Overview</Link></li>
                    <li><Link to="/dashboard/api-keys">API Keys</Link></li>
                    <li><Link to="/dashboard/usage">Usage Statistics</Link></li>
                </ul>
            </div>
            <div className="main-content">
                <Outlet />
            </div>
        </div>
    );
};

export default DashboardLayout;