const processMonthlyData = (data: any) => {
    const requestCounts: { [key: string]: number } = {};
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    for (let day = 1; day <= daysInMonth; day++) {
        const dayLabel = `${month + 1}/${day}`;
        requestCounts[dayLabel] = 0;
    }

    data.usage_data.forEach((item: any) => {
        const date = new Date(item.request_time);
        const label = `${date.getMonth() + 1}/${date.getDate()}`;
        requestCounts[label] = (requestCounts[label] || 0) + 1;
    });

    return requestCounts;
};

export default processMonthlyData;
