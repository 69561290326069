import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Profile.css';
import Spinner from './Spinner';

interface ProfileData {
    username: string;
    email: string;
}

const Profile: React.FC = () => {
    const [profileData, setProfileData] = useState<ProfileData>({ username: '', email: '' });
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/profile`, { withCredentials: true });
                setProfileData(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to load profile data.');
                setLoading(false);
            }
        };

        fetchProfile();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const startEditing = () => {
        setEditing(true);
        setError(null);
        setSuccess(null);
    };

    const handleSaveChanges = async (e: React.FormEvent) => {
        try {
            setLoading(true)
            await axios.put(`${process.env.REACT_APP_API_SERVER}/edit-profile`, profileData, { withCredentials: true });
            setSuccess('Profile updated successfully.')
            setLoading(false)
            setTimeout(() => {
                setSuccess("")
            }, 4000);;
            setEditing(false);
        } catch (err: any) {
            setError('Failed to update profile.')
            setTimeout(() => {
                setError("")
            }, 4000);
            setLoading(false)
            console.error('Update profile error:', err.response || err.message || err);
        }
    };


    return (
        <div className="profile-main-container">
            <div className="profile-card">
                <h2 className="profile-header">Your Profile</h2>
                {error && <p className="profile-message profile-error">{error}</p>}
                {success && <p className="profile-message profile-success">{success}</p>}

                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="profile-form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={profileData.username}
                            onChange={handleInputChange}
                            disabled={!editing}
                        />
                    </div>

                    <div className="profile-form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={profileData.email}
                            onChange={handleInputChange}
                            disabled={!editing}
                        />
                    </div>
                    {loading && <Spinner />}
                    <div className="profile-button-group">
                        {editing ? (
                            <>
                                <button type="submit" className="profile-button profile-save-button" onClick={handleSaveChanges}>
                                    Save Changes
                                </button>
                                <button
                                    type="button"
                                    className="profile-button profile-cancel-button"
                                    onClick={() => setEditing(false)}
                                >
                                    Cancel
                                </button>
                            </>
                        ) : (
                            <button type="button" className="profile-button profile-edit-button" onClick={startEditing}>
                                Edit Profile
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Profile;
