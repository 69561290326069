import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import "./ChartDisplay.css"
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

interface Props {
    chartData: any;
    timePeriod: string;
    api_key: string | null;
}

const ChartDisplay: React.FC<Props> = ({ chartData, timePeriod, api_key }) => {
    const emptyChartData = {
        labels: chartData?.labels || [],
        datasets: [
            {
                label: 'API Requests',
                data: [],
                backgroundColor: 'rgba(192, 192, 192, 0.2)',
                borderColor: 'rgba(192, 192, 192, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="chart-container">
            {!api_key && (
                <div className="error chart-no-api-key-err">
                    <p>Please generate an API key to view your usage data.</p>
                </div>
            )}
            <Bar
                data={api_key ? chartData : emptyChartData}
                options={{
                    responsive: true,
                    plugins: {
                        legend: { position: 'top' },
                        title: { display: true, text: 'API Key Usage' },
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: timePeriod === 'year' ? 'Month' : timePeriod === 'month' ? 'Date' : 'Hour',
                            },
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: timePeriod === 'year' ? 12 : timePeriod === 'month' ? 31 : 24,
                            },
                        },
                        y: {
                            title: { display: true, text: 'Request Count' },
                            beginAtZero: true,
                            ticks: {
                                callback: (value) => (Number.isInteger(value) ? value : ''),
                            },
                        },
                    },
                }}
            />
        </div>
    );
};

export default ChartDisplay;
