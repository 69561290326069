const processDailyData = (data: any) => {
    const requestCounts: { [key: string]: number } = {};

    for (let hour = 0; hour < 24; hour++) {
        requestCounts[`${hour.toString().padStart(2, '0')}:00`] = 0;
    }

    data.usage_data.forEach((item: any) => {
        const date = new Date(item.request_time);
        const label = `${date.getHours().toString().padStart(2, '0')}:00`;
        requestCounts[label] = (requestCounts[label] || 0) + 1;
    });

    return requestCounts;
};

export default processDailyData;
