import React from 'react';
import './Spinner.css'; // Spinner styling

interface SpinnerProps {
    color?: string; // Optional color prop
}

const Spinner: React.FC<SpinnerProps> = ({ color = 'var(--primary-blue)' }) => { 
    return (
        <div className="spinner-container">
            <div className="spinner" style={{ borderLeftColor: color }}></div>
        </div>
    );
};

export default Spinner;
