import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ApiKeys.css';
import GenerateApiKey from './GenerateApiKey';
import ApiKeyPopup from './ApiKeyPopup'; // Import the popup component

interface ApiKey {
    id: number;
    name: string;
    created_at: string;
    api_key: string;
    last_used: string;
}

const ApiKeys: React.FC = () => {
    const [apiKey, setApiKey] = useState<ApiKey | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isPopupVisible, setPopupVisible] = useState(false);

   useEffect(() => {
    const fetchApiKey = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/get-api-key`, { withCredentials: true });
            setApiKey(response.data);
        } catch (error) {
            console.error('Error fetching API key:', error);

            // Safely check if error is an AxiosError and has a response message
            if (axios.isAxiosError(error) && error.response) {
                setError(error.response.data?.message || 'Failed to fetch API key.');
            } else {
                setError('An unknown error occurred.');
            }
        } finally {
            setLoading(false);
        }
    };
    fetchApiKey();
}, []);


    const handleClosePopup = () => {
        setPopupVisible(false);
    };

    return (
        <div className="api-keys-container">
            <h3 className="title">Your API Key</h3>

            <p className="notice">
                <strong>Note:</strong> Generating a new API key will invalidate your old one.
                Make sure to update your applications using the old key.
            </p>

            <GenerateApiKey
                onApiKeyGenerated={(newApiKey) => {
                    setApiKey(newApiKey);
                    setPopupVisible(true);
                }}
                onShowPopup={() => setPopupVisible(true)}
            />

            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : error ? (
                <p className="error">{error}</p>
            ) : apiKey ? (
                <div className="api-key-card">
                    <div className="api-key-detail"><strong>{apiKey.name}</strong></div>
                    <div className="api-key-detail">{apiKey.api_key.slice(0, 8) + '...' + apiKey.api_key.slice(-4)}</div>
                    <div className="api-key-detail">{new Date(apiKey.created_at).toLocaleDateString()}</div>
                    <div className="api-key-detail">{apiKey.last_used ? new Date(apiKey.last_used).toLocaleDateString() : 'Never'}</div>
                </div>
            ) : (
                <p>No API key available. Click "Generate New API Key" to create one.</p>
            )}

            {isPopupVisible && (
                <ApiKeyPopup apiKey={apiKey?.api_key || ''} onClose={handleClosePopup} />
            )}
        </div>
    );
};

export default ApiKeys;
