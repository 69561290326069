import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UsageStatistics.css';
import UsageInfo from './UsageInfo';
import ChartDisplay from './ChartDisplay';
import processMonthlyData from "../helpers/processMonthlyData";
import processYearlyData from '../helpers/processYearlyData';
import processDailyData from '../helpers/processDailyData';
import TimePeriodSelector from './TimePeriodSelector';

const UsageStatistics: React.FC = () => {
    const [chartData, setChartData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [timePeriod, setTimePeriod] = useState<string>('day');
    const [requestCount, setRequestCount] = useState<number>(0);
    const [lastUsed, setLastUsed] = useState<string>('');
    const [api_key, setApiKey] = useState<string>('');
    const [usageData, setUsageData] = useState<any>(null); // Store fetched data

    const fetchApiKeyAndData = async () => {
        setLoading(true); // Show spinner while loading
        try {
            const keyResponse = await axios.get(`${process.env.REACT_APP_API_SERVER}/get-api-key`, { withCredentials: true });
            const key = keyResponse.data;
            setApiKey(key.api_key);

            if (!key.id) {
                throw new Error('API Key ID is missing');
            }

            const dataResponse = await axios.get(`${process.env.REACT_APP_API_SERVER}/usage`, {
                params: { apiKeyId: key.id },
                withCredentials: true
            });

            if (dataResponse.status !== 200) {
                throw new Error('Failed to fetch usage data');
            }

            const data = dataResponse.data;
            const { request_count, last_used } = data;

            setRequestCount(request_count);
            setLastUsed(new Date(last_used).toLocaleString());
            setUsageData(data); // Store data for further processing

            processAndSetChartData(data); // Process data based on initial time period
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Hide spinner when loading is done
        }
    };


    const processAndSetChartData = (data: any) => {
        const requestCounts = processUsageData(data);
        const labels = Object.keys(requestCounts);
        const values = labels.map(label => requestCounts[label]);

        setChartData({
            labels,
            datasets: [
                {
                    label: 'API Requests',
                    data: values,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
            ],
        });
    };

    const processUsageData = (data: any) => {
        let requestCounts: { [key: string]: number } = {};

        if (timePeriod === 'month') {
            requestCounts = processMonthlyData(data);
        } else if (timePeriod === 'year') {
            requestCounts = processYearlyData(data);
        } else if (timePeriod === 'day') {
            requestCounts = processDailyData(data);
        }

        return requestCounts;
    };

    useEffect(() => {
        fetchApiKeyAndData();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (usageData) {
            processAndSetChartData(usageData); // Reprocess data on time period change
        }
        //eslint-disable-next-line
    }, [timePeriod]);

    return (
        <div className="usage-statistics">
            <div className="usage-title-header">
                <UsageInfo requestCount={requestCount} lastUsed={lastUsed} />
                <h2>Usage Statistics</h2>
            </div>

            <div className="filters">
                <TimePeriodSelector timePeriod={timePeriod} onTimePeriodChange={setTimePeriod} />
            </div>

            {loading ? (
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div>
                    <ChartDisplay chartData={chartData} timePeriod={timePeriod} api_key={api_key} />
                </div>
            )}
        </div>
    );
};

export default UsageStatistics;
