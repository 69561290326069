import axios from 'axios';
import React, { useState, useEffect } from 'react';
import './Auth.css';
import Spinner from './Spinner'; // Import the spinner component

const Register: React.FC = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const [cookiesAccepted, setCookiesAccepted] = useState<boolean>(false); // Track cookie consent


  // Check localStorage for cookie consent when the component mounts and periodically
  useEffect(() => {
    const checkConsent = () => {
      const consent = localStorage.getItem('cookiesAccepted');
      setCookiesAccepted(consent === 'true');
    };

    // Check on mount
    checkConsent();

    // Optionally, use an interval to check periodically (every 1 second)
    const interval = setInterval(checkConsent, 1000);

    // Cleanup interval when component unmounts
    return () => clearInterval(interval);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true); // Show spinner when submitting

    try {
      await axios.post(`${process.env.REACT_APP_API_SERVER}/register`, {
        username: formData.username,
        email: formData.email,
        password: formData.password,
      }, { withCredentials: true });

      setSuccess('We sent you an email, go verify your account.');
      setFormData({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
      });
      setError(null);
    } catch (error: any) {
      setError(error.response?.data?.message || 'Registration failed');
      setSuccess(null);
    } finally {
      setLoading(false); // Hide spinner when request completes
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-box">
        <h2>Create Your Account</h2>

        {loading ? (
          <Spinner color="white" />
        ) : (
          <form className="auth-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                required
                disabled={!cookiesAccepted} // Disable input if cookies not accepted
              />
            </div>
            <div className="input-group">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
                disabled={!cookiesAccepted} // Disable input if cookies not accepted
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
                disabled={!cookiesAccepted} // Disable input if cookies not accepted
              />
            </div>
            <div className="input-group">
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                disabled={!cookiesAccepted} // Disable input if cookies not accepted
              />
            </div>
            {error && <p className="error">{error}</p>}
            {success && <p className="success-verify-email-msg">{success}</p>}
            <button type="submit" className="submit-btn" disabled={!cookiesAccepted || loading}>
              {loading ? 'Registering...' : 'Register'}
            </button>
            {!cookiesAccepted && (
              <p className="consent-warning">Please accept cookies to register.</p>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Register;
