// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Spinner from './Spinner';

const ProtectedRoute: React.FC = () => {
  const { isAuthenticated, loadingAuth } = useAuth();

  if (loadingAuth) {
    return <Spinner />; // Display a loading indicator while auth check is in progress
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
