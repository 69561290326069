import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>
      <p><strong>Last updated:</strong> September 17, 2024</p>

      <p>Welcome to flashcardsapi.com! These Terms of Service govern your use of our website and services. By accessing or using our Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our Service.</p>

      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>The words with capitalized first letters have meanings defined under the following conditions. These definitions shall have the same meaning whether they appear in singular or plural form.</p>

      <h3>Definitions</h3>
      <p>For the purposes of these Terms of Service:</p>
      <ul>
        <li><strong>Account</strong> means a unique account created for you to access our Service.</li>
        <li><strong>Company</strong> (referred to as either "the Company", "We", "Us", or "Our" in this Agreement) refers to flashcardsapi.</li>
        <li><strong>Service</strong> refers to the Website and any other services provided by flashcardsapi.</li>
        <li><strong>Website</strong> refers to flashcardsapi.com, accessible from <a href="https://flashcardsapi.com">https://flashcardsapi.com</a>.</li>
        <li><strong>You</strong> refers to the individual accessing or using the Service, or the company or legal entity on behalf of which such individual is accessing or using the Service.</li>
      </ul>

      <h2>Usage of the Service</h2>
      <h3>Accounts</h3>
      <p>To use certain features of the Service, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

      <p>By creating an account, you agree to:</p>
      <ul>
        <li>Provide accurate, complete, and current information.</li>
        <li>Keep your password secure and confidential.</li>
        <li>Notify us immediately of any breach of security or unauthorized use of your account.</li>
      </ul>

      <h3>Use Restrictions</h3>
      <p>You agree not to:</p>
      <ul>
        <li>Use the Service for any unlawful purpose.</li>
        <li>Violate any applicable laws or regulations.</li>
        <li>Interfere with or disrupt the Service or servers.</li>
      </ul>

      <h2>Cookies and Analytics</h2>
      <p>We use third-party cookies to help us analyze how our Service is used and to improve your experience. These cookies may collect information about your usage of our Service, such as pages viewed and time spent on the site. By using our Service, you consent to the use of these cookies. For more details, please refer to our <a href="/privacy-policy">Privacy Policy</a>.</p>

      {/* Add more sections as needed */}
    </div>
  );
};

export default TermsOfService;
