// src/components/Layout.tsx
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import "./MainLayout.css"

const MainLayout: React.FC = () => {
  return (
    <div className="layout-container">
      <main className="layout-main">
        <Outlet />
      </main>
      <footer className="footer">
        <p>© 2024 Flashcards API. All Rights Reserved.</p>
        <nav className="footer-nav">
          <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
        </nav>
      </footer>
    </div>
  );
};

export default MainLayout;
