const processYearlyData = (data: any) => {
    const requestCounts: { [key: string]: number } = {};

    for (let month = 0; month < 12; month++) {
        const monthName = new Date(0, month).toLocaleString('default', { month: 'long' });
        requestCounts[monthName] = 0;
    }

    data.usage_data.forEach((item: any) => {
        const date = new Date(item.request_time);
        const label = date.toLocaleString('default', { month: 'long' });
        requestCounts[label] = (requestCounts[label] || 0) + 1;
    });

    return requestCounts;
};

export default processYearlyData;
