import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

interface AuthContextProps {
    isAuthenticated: boolean;
    setAuth: (authenticated: boolean, userData?: UserData) => void;
    logout: () => void;
    user_id: number | null; 
    username: string | null;
    email: string | null;
    loadingAuth: boolean; // New: track if auth check is in progress
}

interface UserData {
    user_id: number | null; 
    username: string | null;
    email: string | null;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [user_id, setUserId] = useState<number | null>(null);
    const [username, setUsername] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [loadingAuth, setLoadingAuth] = useState<boolean>(true); // New: loading state
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_SERVER}/check-auth`, { withCredentials: true });
                if (response.status === 200) {
                    setIsAuthenticated(true);
                    setUserId(response.data.user.user_id);
                    setUsername(response.data.user.username);
                    setEmail(response.data.user.email);
                }
            } catch (error) {
                // Do not force a logout on checkAuth failure
                console.error('Auth check failed:', error);
            } finally {
                setLoadingAuth(false); // New: stop loading once check completes
            }
        };

        checkAuth();
        //eslint-disable-next-line
    }, []);

    const setAuth = (authenticated: boolean, userData?: UserData) => {
        setIsAuthenticated(authenticated);
        if (authenticated && userData) {
            setUserId(userData.user_id);
            setUsername(userData.username);
            setEmail(userData.email);
        }
        if (!authenticated) {
            Cookies.remove('token'); 
            setUserId(null);
            setUsername(null);
            setEmail(null);
        }
    };

    const logout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_SERVER}/logout`, {}, { withCredentials: true });
            setAuth(false);
            navigate("/login");
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setAuth, logout, user_id, username, email, loadingAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = (): AuthContextProps => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
