import React, { useState } from 'react';
import axios from 'axios';
import './GenerateApiKey.css';

interface GenerateApiKeyProps {
    onApiKeyGenerated: (apiKey: any) => void; // Callback to update the parent component
    onShowPopup: () => void; // Callback to show the popup
}

const GenerateApiKey: React.FC<GenerateApiKeyProps> = ({ onApiKeyGenerated, onShowPopup }) => {
    const [apiKeyName, setApiKeyName] = useState<string>('Secret Key');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleGenerateKey = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/generate-api-key`, { name: apiKeyName }, { withCredentials: true });
            onApiKeyGenerated(response.data); // Pass the new API key to the parent component
            onShowPopup(); // Show the popup
            setApiKeyName(''); // Clear the input field
        } catch (error) {
            console.error('Error generating API key:', error);
            setError('Failed to generate new API key.');
            setTimeout(() => {
                setError("")
            }, 4000);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="generate-api-key">
            <h4 className="section-title">Generate New API Key</h4>
            <div className='generate-key-input-wrapper'>
                <input
                    type="text"
                    value={apiKeyName}
                    onChange={(e) => setApiKeyName(e.target.value)}
                    placeholder="Enter Key name (optional)"
                    className="api-key-name-input"
                />
                <button onClick={handleGenerateKey} className="generate-key-btn" disabled={loading}>
                    {loading ? <div className="generate-key-btn-spinner"></div> : 'New API Key'}
                </button>
            </div>
            {error && <p className="generate-key-error">{error}</p>}
        </div>
    );
};

export default GenerateApiKey;
