import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; // For GitHub Flavored Markdown support (tables)
import './ApiDocs.css';

const APIDocs: React.FC = () => {
  const jsonExample = `
\`\`\`json
[
    {
        "word": "año",
        "grammar_type": "noun",
        "translation": "year"
    },
    {
        "word": "persona",
        "grammar_type": "noun",
        "translation": "person"
    }
]
\`\`\`
`;

  return (
    <div className="api-docs-container">
      <h1 className="api-docs-header">API Documentation</h1>
      
      <section className="api-docs-section">
        <h2>Overview</h2>
        <p className="api-docs-paragraph">Welcome to the API documentation for our Flashcards API. Below you will find information on how to use our API to manage and retrieve flashcard data.</p>
      </section>

      <section className="api-docs-section">
        <h2>Endpoints</h2>
        <h3>Get Flashcards</h3>
        <p className="api-docs-paragraph"><strong>Endpoint:</strong> <code className="api-docs-code">GET /api/flashcards</code></p>
        <p className="api-docs-paragraph"><strong>Parameters:</strong></p>
        <ul className="api-docs-list">
          <li><strong><code className="api-docs-code">language</code></strong> (string): The language for which you want to retrieve flashcards. Example: <code className="api-docs-code">spanish</code></li>
          <li><strong><code className="api-docs-code">grammar_type</code></strong> (string, optional): The type of grammar to filter the flashcards. Example: <code className="api-docs-code">noun</code></li>
          <li><strong><code className="api-docs-code">limit</code></strong> (number, optional): The number of flashcards to retrieve. Example: <code className="api-docs-code">10</code></li>
        </ul>
        <p className="api-docs-paragraph"><strong>Authentication:</strong></p>
        <p className="api-docs-paragraph">The API requires authentication via an API key, which you must send in the request header.</p>
        <ul className="api-docs-list">
          <li><strong>Header:</strong> <code className="api-docs-code">x-api-key: YOUR_API_KEY</code></li>
        </ul>
      </section>

      <section className="api-docs-section">
        <h2>Available Languages</h2>
        <p className="api-docs-paragraph">You can request flashcards for the following supported languages:</p>
        <ul className="api-docs-list">
          <li><strong>Spanish</strong> (code: <code className="api-docs-code">spanish</code>)</li>
          <li><strong>French</strong> (code: <code className="api-docs-code">french</code>)</li>
          <li><strong>German</strong> (code: <code className="api-docs-code">german</code>)</li>
          <li><strong>Japanese</strong> (code: <code className="api-docs-code">japanese</code>)</li>
          <li><strong>Russian</strong> (code: <code className="api-docs-code">russian</code>)</li>
        </ul>
      </section>

      <section className="api-docs-section">
        <h2>Example Request</h2>
        <p className="api-docs-paragraph">Here’s an example of how to make a request to the API:</p>
        <pre className="api-docs-code-block">
          <code>
            {`fetch('${process.env.REACT_APP_API_SERVER}/api/flashcards?language=spanish&grammar_type=noun&limit=2', {
        method: 'GET',
        headers: {
          'x-api-key': 'YOUR_API_KEY'
        }
        })
        .then(response => response.json())
        .then(data => console.log(data))
        .catch(error => console.error('Error:', error));`}
          </code>
        </pre>
      </section>

      <section className="api-docs-section">
        <h2>Response Format</h2>
        <p className="api-docs-paragraph">The response from the API will be in JSON format with the following structure:</p>
        <ReactMarkdown className="api-docs-example" remarkPlugins={[remarkGfm]}>{jsonExample}</ReactMarkdown>
      </section>

      <section className="api-docs-section">
        <h2>Error Handling</h2>
        <p className="api-docs-paragraph">The API may return the following common errors:</p>
        <ul className="api-docs-list">
          <li><strong>400 Bad Request:</strong> The request was invalid. Check your parameters and request format.</li>
          <li><strong>401 Unauthorized:</strong> The API key is missing or invalid. Make sure you include a valid API key.</li>
          <li><strong>404 Not Found:</strong> The endpoint or resource was not found. Verify the endpoint URL.</li>
          <li><strong>500 Internal Server Error:</strong> An error occurred on the server. Try again later.</li>
        </ul>
      </section>

      <section className="api-docs-section">
        <h2>Rate Limits</h2>
        <p className="api-docs-paragraph">Our API has rate limits to ensure fair use and maintain performance:</p>
        <ul className="api-docs-list">
          <li><strong>Requests per minute:</strong> 100 requests</li>
          <li><strong>Requests per hour:</strong> 1000 requests</li>
        </ul>
        <p className="api-docs-paragraph">Exceeding these limits will result in a <code className="api-docs-code">429 Too Many Requests</code> error. Please wait before making additional requests.</p>
      </section>
    </div>
  );
};

export default APIDocs;
