import React from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  return (
    <div className="homepage-container">
      <header className="homepage-header">
        <div className="homepage-header-content">
          <h1 className="homepage-title">Flashcards API</h1>
          <p className="homepage-subtitle">
            Enhance your language learning applications with the power of our API.
          </p>
        </div>
      </header>

      <main className="homepage-main">
        <section className="homepage-features">
          <h2 className="homepage-features-title">Why Use Flashcards API?</h2>
          <div className="homepage-features-list">
            <div className="feature-item">
              <img src="images/1.png" alt="Vocabulary" className="feature-icon" />
              <p>Curated vocabulary lists</p>
            </div>
            <div className="feature-item">
              <img src="images/2.png" alt="Translations" className="feature-icon" />
              <p>Accurate translations</p>
            </div>
            <div className="feature-item">
              <img src="images/3.png" alt="Grammar" className="feature-icon" />
              <p>Grammar-based sorting</p>
            </div>
          </div>
        </section>

        {!isAuthenticated && (
          <section className="homepage-cta">
            <h2 className="homepage-cta-title">Start Today</h2>
            <p className="homepage-cta-text">
              Get your API key and enhance your app with tailored vocabulary.
            </p>
            <button className="homepage-cta-button" onClick={() => navigate("register")}>Sign Up</button>
          </section>
        )}

      </main>
    </div>
  );
};

export default HomePage;
