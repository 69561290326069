import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Overview.css';

const Overview: React.FC = () => {
    return (
        <div className="overview-container">
            <h2>Welcome to Your Dashboard</h2>

            <div className="cards-container"> {/* Container for the cards */}
                <Link to="/dashboard/api-keys" className="card">
                    <h3>API Keys</h3>
                    <p>Manage your API keys to access our flashcard language learning data.</p>
                </Link>

                <Link to="/dashboard/usage" className="card">
                    <h3>Usage Statistics</h3>
                    <p>View detailed statistics about your API usage.</p>
                </Link>

                {/* Add more cards for other features as needed */}
            </div>
        </div>
    );
};

export default Overview;