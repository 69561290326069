import React from 'react';

interface Props {
    timePeriod: string;
    onTimePeriodChange: (period: string) => void;
}

const TimePeriodSelector: React.FC<Props> = ({ timePeriod, onTimePeriodChange }) => {
    return (
        <select value={timePeriod} onChange={(e) => onTimePeriodChange(e.target.value)}>
            <option value="day">Daily</option>
            <option value="month">Monthly</option>
            <option value="year">Yearly</option>
        </select>
    );
};

export default TimePeriodSelector;
