import React from 'react';
import './UsageInfo.css';

interface Props {
    requestCount: number;
    lastUsed: string;
}

const UsageInfo: React.FC<Props> = ({ requestCount, lastUsed }) => {
    return (
        <div className="request-info">
            <h4>Total Requests: {requestCount}</h4>
            <h4>
                Last Used: {new Date(lastUsed).getFullYear() === 1969
                    ? "N/A"
                    : new Date(lastUsed).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
            </h4>
        </div>
    );
};

export default UsageInfo;
